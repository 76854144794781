// hoverHighlightStyle.ts

import {SxProps, Theme} from '@mui/material';
import {useState} from 'react';

export const hoverHighlight = () => {
  const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (e: React.MouseEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  const sx: SxProps<Theme> = {
    position: 'relative',
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.03)',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 60%)`,
      opacity: isHovering ? 1 : 0,
      transition: 'opacity 0.3s ease',
    },
  };

  return {
    sx,
    eventHandlers: {
      onMouseMove: handleMouseMove,
      onMouseEnter: () => setIsHovering(true),
      onMouseLeave: () => setIsHovering(false),
    },
  };
};
