import {InputAdornment, IconButton} from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';

interface SearchBarProps {
  searchTerm: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClearSearch: () => void;
  placeholder?: string;
}

export default function SearchBar({
  searchTerm,
  onSearchChange,
  onClearSearch,
  placeholder = 'Search...',
}: SearchBarProps) {
  return (
    <TextField
      data-testid="lake-search"
      variant="outlined"
      placeholder={placeholder}
      label="Search"
      value={searchTerm}
      onChange={onSearchChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchTerm && (
          <InputAdornment position="end">
            <IconButton
              onClick={onClearSearch}
              edge="end"
              data-testid="clear-button"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        mb: 2,
        width: '50%',
      }}
    />
  );
}
