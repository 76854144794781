import {Box, SelectChangeEvent, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import MapComponent from './Map';

import {
  columns,
  WaterSample,
  getDrinkingWaterData,
  getLakeNames,
  getHistoricalPhDataOfLake,
  getPollutantDataOfLake,
  getPollutantDataOfAllLakes,
  getLakeCoordinates,
} from '../internals/data/drinkingWater';
import React, {useEffect, useState} from 'react';
import NumberCard, {NumberCardProps} from './NumberCard';
import Grid from '@mui/material/Grid2';
import LineChartCard, {LineChartData} from './LineChartCard';
import BarChartCard, {BarChartData} from './BarChartCard';
import Logo from './Logo';
import LakeSelect from './LakeFilter';
import SearchBar from './SearchBar';
import L from 'leaflet';
import Stream from './Stream';

// Overridding styles for DataGrid component
const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row': {
    background: '#05070a',
  },
  '& .MuiDataGrid-row:hover': {
    background: '#12151D',
  },
  '& .MuiDataGrid-container--top [role=row]': {
    background: '#0C1017',
  },
}));

export default function Home() {
  const [cards, setCards] = useState<NumberCardProps[]>([]);
  const [historicalPhData, setHistoricalPhData] = useState<LineChartData[]>([]);
  const [averageLakeData, setAverageLakeData] = useState<BarChartData[]>([]);
  const [drinkingWaterData, setDrinkingWaterData] = useState<WaterSample[]>([]);

  const [lake, setLake] = useState('');
  const [lakeNames, setLakeNames] = useState<string[]>([]);
  const [lakeCoordinates, setLakeCoordinates] = useState<L.LatLng>();

  const handleLakeChange = (event: SelectChangeEvent) => {
    const lakeName = event.target.value;
    setLake(lakeName);
    console.log('selected: ' + lakeName);

    // display historical ph of selected lake
    getHistoricalPhDataOfLake(lakeName).then(data => setHistoricalPhData(data));

    // display pollutant level of selected lake
    getPollutantDataOfLake(lakeName).then(data => setAverageLakeData(data));

    // get coordinates of lake and set them in the map
    getLakeCoordinates(lakeName).then(data => setLakeCoordinates(data));
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(drinkingWaterData);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  // Debounced search logic
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm === '') {
        setFilteredData(drinkingWaterData);
      } else {
        const filteredRows = drinkingWaterData.filter(row =>
          Object.values(row).some(val =>
            String(val).toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
        setFilteredData(filteredRows);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, drinkingWaterData]);

  const handleClearSearch = () => {
    setSearchTerm('');
    setFilteredData(drinkingWaterData);
  };

  useEffect(() => {
    // get lake names to fill dropdown
    getLakeNames().then(data => setLakeNames(data));

    // get drinking water data
    getDrinkingWaterData().then(data => setDrinkingWaterData(data));

    // display averages
    getPollutantDataOfAllLakes().then(data => setCards(data));
    getPollutantDataOfAllLakes().then(data => console.log(data));
  }, []);

  return (
    <Box
      sx={{
        p: 7,
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          mb: 3,
          fontWeight: 'bold',
          color: 'primary.main',
          textAlign: 'center',
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
          letterSpacing: '0.1em',
        }}
      ></Typography>

      <Logo></Logo>

      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>

      <Grid
        container
        spacing={2}
        columns={12}
        sx={{mb: theme => theme.spacing(2), my: 6}}
      >
        {cards.map(card => {
          return (
            <Grid key={JSON.stringify(card)} size={{xs: 12, sm: 6, lg: 2}}>
              <NumberCard title={card.title} value={card.value}></NumberCard>
            </Grid>
          );
        })}
      </Grid>

      <LakeSelect
        lakes={lakeNames}
        selectedLake={lake}
        onLakeChange={handleLakeChange}
        dataTestId="select"
      />

      <Grid
        container
        spacing={2}
        columns={12}
        sx={{mb: theme => theme.spacing(2)}}
      >
        <Grid size={{sm: 12, md: 6}}>
          <LineChartCard
            title={'Historical Water pH'}
            valueName={'pH'}
            data={historicalPhData}
          />
        </Grid>
        <Grid size={{sm: 12, md: 6}}>
          <BarChartCard
            title={'Pollutant Levels (mg/L)'}
            data={averageLakeData}
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        columns={12}
        sx={{mb: theme => theme.spacing(2)}}
      >
        <Grid size={{sm: 12, md: 6}}>
          <Stream title={'Stream Visualization'} data={averageLakeData} />
        </Grid>
        <Grid size={{sm: 12, md: 6}}>
          <MapComponent
            coordinatesProp={lakeCoordinates}
            locationName={lake}
            zoomProp={15}
            titleProp="Location"
          />
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom sx={{my: 4}}>
        All Lakes
      </Typography>

      <SearchBar
        searchTerm={searchTerm}
        onSearchChange={handleSearchChange}
        onClearSearch={handleClearSearch}
        placeholder="Search by Lake"
      />

      <StyledDataGrid
        rows={searchTerm ? filteredData : drinkingWaterData}
        columns={columns}
        initialState={{
          pagination: {paginationModel: {pageSize: 20}},
        }}
        pageSizeOptions={[10, 20, 50]}
        slots={{toolbar: GridToolbar}}
      />
    </Box>
  );
}
