import * as React from 'react';
import {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {hoverHighlight} from './hoverHighlight';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  ReferenceLine,
} from 'recharts';

export type BarChartData = {
  name: string;
  quantity: string;
};

export type BarChartCardProps = {
  title: string;
  data: BarChartData[];
};

// Define color constants
const STROKE_COLOR = '#93c5fd';
const FILL_COLOR = '#3b82f6';
const REFERENCE_LINE_COLOR = 'orange';

export default function BarChartCard({
  title,
  data,
}: Readonly<BarChartCardProps>) {
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [average, setAverage] = useState<number | null>(null);

  useEffect(() => {
    if (data.length > 0) {
      const total = data.reduce(
        (sum, item) => sum + parseFloat(item.quantity),
        0
      );
      setAverage(total / data.length);
      setLastUpdated(new Date().toLocaleString());
    }
  }, [data]);

  const {sx, eventHandlers} = hoverHighlight();

  return (
    <Card
      variant="outlined"
      sx={{height: '100%', flexGrow: 1, ...sx}}
      {...eventHandlers}
    >
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#1e3a8a" />
            <XAxis dataKey="name" stroke={STROKE_COLOR} />
            <YAxis stroke={STROKE_COLOR} />
            <Tooltip />
            <Bar dataKey="quantity" fill={FILL_COLOR} />
            {average !== null && (
              <ReferenceLine
                y={average}
                stroke={REFERENCE_LINE_COLOR}
                strokeDasharray="3 3"
                label="Average"
              />
            )}
          </BarChart>
        </ResponsiveContainer>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{marginTop: '10px'}}
        >
          Last Updated: {lastUpdated ?? 'Loading...'}
        </Typography>
      </CardContent>
    </Card>
  );
}
