import {GridColDef} from '@mui/x-data-grid';
import {BarChartData} from '../../components/BarChartCard';
import {LineChartData} from '../../components/LineChartCard';
import {NumberCardProps} from '../../components/NumberCard';
import L from 'leaflet';

// Fetch drinkingWaterData

export type WaterSample = {
  id: string;
  lake: string;
  stationCode: string;
  station: string;
  sampleDate: string; // Assuming the date is formatted as a string
  granAlkalinity: number;
  ca: number;
  cl: number;
  specificConductance: number;
  dic: number;
  doc: number;
  fe: number;
  potassium: number;
  mg: number;
  na: number;
  nh4Nh3: number;
  no3No2: number;
  tkn: number;
  tn: number;
  ph: number;
  tp: number;
  sio3: number;
  so4: number;
  hardness: number;
};

export type WaterSampleAverages = {
  averageGranAlkalinity: number;
  averageCa: number;
  averageCl: number;
  averageSpecificConductance: number;
  averageDic: number;
  averageDoc: number;
  averageFe: number;
  averagePotassium: number;
  averageMg: number;
  averageNa: number;
  averageNh4Nh3: number;
  averageNo3No2: number;
  averageTkn: number;
  averageTn: number;
  averagePh: number;
  averageTp: number;
  averageSio3: number;
  averageSo4: number;
  averageHardness: number;
};

export async function getDrinkingWaterData(): Promise<WaterSample[]> {
  const response = await fetch('/api/waterwise/all/inland-water-quality');
  return await response.json();
}

export async function getLakeNames() {
  const response = await fetch('/api/waterwise/lakes/inland-water-quality');
  return response.json();
}

async function getLakeData(lake: string) {
  const response = await fetch(
    `/api/waterwise/all/inland-water-quality?lake=${encodeURIComponent(lake)}`
  );
  return response.json();
}

async function getAvgOfLake(lake: string) {
  const response = await fetch(
    `/api/waterwise/average/inland-water-quality?lake=${encodeURIComponent(lake)}`
  );
  return response.json();
}

async function getAvgAllLakes(): Promise<WaterSampleAverages> {
  let response;
  try {
    response = await fetch('/api/waterwise/average/inland-water-quality');
    if (!response.ok) {
      throw new Error(`HTTP Error with response ${response.statusText}`);
    }
  } catch (e) {
    // Return empty data if the fetch failed
    return {
      averageGranAlkalinity: 0,
      averageCa: 0,
      averageCl: 0,
      averageSpecificConductance: 0,
      averageDic: 0,
      averageDoc: 0,
      averageFe: 0,
      averagePotassium: 0,
      averageMg: 0,
      averageNa: 0,
      averageNh4Nh3: 0,
      averageNo3No2: 0,
      averageTkn: 0,
      averageTn: 0,
      averagePh: 0,
      averageTp: 0,
      averageSio3: 0,
      averageSo4: 0,
      averageHardness: 0,
    };
  }

  return response.json();
}

// The column structure of the DataGrid in the Home page
export const columns: GridColDef[] = [
  {field: 'lake', headerName: 'Lake', flex: 1.5, minWidth: 150},
  {
    field: 'hardness',
    headerName: 'Water Hardness (mg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'ph',
    headerName: 'pH',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'granAlkalinity',
    headerName: 'Gran Alkalinity (mg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 60,
  },
  {
    field: 'ca',
    headerName: 'Ca (mg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'specificConductance',
    headerName: 'Specific Conductance (μS/cm)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'fe',
    headerName: 'Fe (mcg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
  {
    field: 'na',
    headerName: 'Na (mg/L)',
    headerAlign: 'right',
    align: 'right',
    flex: 1,
    minWidth: 50,
  },
];

export async function getHistoricalPhDataOfLake(
  lake: string
): Promise<LineChartData[]> {
  const lakeData = await getLakeData(lake);
  return Array.from({length: lakeData.length}, (_, i) => ({
    x: new Date(lakeData[i].sampleDate).toLocaleDateString(), // date
    y: `${lakeData[i].ph}`, // pH
  }));
}

export async function getPollutantDataOfLake(
  lake: string
): Promise<BarChartData[]> {
  const lakeData = await getAvgOfLake(lake);
  return [
    {
      name: 'Calcium',
      quantity: lakeData.averageCa.toFixed(2),
    },
    {
      name: 'Chlorine',
      quantity: lakeData.averageCl.toFixed(2),
    },
    {
      name: 'Potassium',
      quantity: lakeData.averagePotassium.toFixed(2),
    },
    {
      name: 'Magnesium',
      quantity: lakeData.averageMg.toFixed(2),
    },
    {
      name: 'Sodium',
      quantity: lakeData.averageNa.toFixed(2),
    },
  ];
}

export async function getPollutantDataOfAllLakes(): Promise<NumberCardProps[]> {
  const lakeData = await getAvgAllLakes();
  return [
    {
      title: 'Average pH',
      value: `${lakeData.averagePh.toFixed(2)}`,
    },
    {
      title: 'Average Calcium',
      value: `${lakeData.averageCa.toFixed(2)} mg/L`,
    },
    {
      title: 'Average Chlorine',
      value: `${lakeData.averageCl.toFixed(2)} mg/L`,
    },
    {
      title: 'Average Potassium',
      value: `${lakeData.averagePotassium.toFixed(2)} mEq/L`,
    },
    {
      title: 'Average Magnesium',
      value: `${lakeData.averageMg.toFixed(2)} mg/L`,
    },
    {
      title: 'Average Sodium',
      value: `${lakeData.averageNa.toFixed(2)} mg/L`,
    },
  ];
}

export async function getLakeCoordinates(lake: string): Promise<L.LatLng> {
  const response = await fetch(
    `/api/waterwise/get/coords?lake=${encodeURIComponent(lake)}`
  );
  const data = await response.json();
  return new L.LatLng(data.latitude, data.longitude);
}
