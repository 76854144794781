import React from 'react';
import './index.css';
import {Box} from '@mui/material';
import Home from './components/Home';

const App: React.FC = () => {
  return (
    <Box
      sx={{
        px: 3,
        pt: 1.5,
        boxSizing: 'borderBox',
        background: '#121212',
      }}
    >
      <Box
        sx={() => ({
          width: '100%',
          maxWidth: {sm: '100%'},
        })}
      >
        <Home />
      </Box>
    </Box>
  );
};

export default App;
