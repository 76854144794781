import React, {useEffect, useRef} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

interface MapProps {
  coordinatesProp?: L.LatLng;
  locationName?: string;
  zoomProp?: number;
  titleProp?: string;
}

const defaultLocation: L.LatLng = new L.LatLng(45.4215, -75.6972); // Example coordinates
const MapComponent: React.FC<MapProps> = ({
  coordinatesProp = defaultLocation,
  locationName = '',
  zoomProp,
  titleProp,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstance = useRef<L.Map | null>(null);
  // const markerInstance = useRef<L.Marker | null>(null);

  useEffect(() => {
    // Initialize the map only once
    if (mapRef.current && !mapInstance.current) {
      mapInstance.current = L.map(mapRef.current).setView(coordinatesProp, 8);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapInstance.current);
    }

    return () => {
      mapInstance.current?.remove(); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
    mapInstance.current!.setView(coordinatesProp, zoomProp);
  }, [coordinatesProp, zoomProp]);

  return (
    <Card
      variant="outlined"
      sx={{height: '500px', width: '1', margin: 'auto', flexGrow: 1}}
    >
      <CardContent>
        <div>
          {/* <input
            type="text"
            value={address}
            onChange={e => setAddress(e.target.value)}
            placeholder="Enter address"
          /> */}
          {/* <button onClick={() => convertAddress(address)}>
            Convert Address
          </button> */}
        </div>
        <Typography variant="h6" gutterBottom>
          {titleProp}
        </Typography>
        <div ref={mapRef} style={{height: '400px', width: '100%'}}></div>
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{marginTop: '10px'}}
        >
          Location: {locationName}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MapComponent;
