import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
} from 'recharts';

export type LineChartData = {
  x: string;
  y: string;
};

export type LineChartCard = {
  title: string;
  valueName: string;
  data: LineChartData[];
};

export default function LineChartCard({
  title,
  valueName,
  data,
}: Readonly<LineChartCard>) {
  return (
    <Card variant="outlined" sx={{height: '100%', flexGrow: 1}}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#1e3a8a" />
            <XAxis dataKey="x" stroke="#93c5fd" />
            <YAxis stroke="#93c5fd" />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="y"
              name={valueName}
              stroke="#3b82f6"
            />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
