import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {hoverHighlight} from './hoverHighlight';

export type NumberCardProps = {
  title: string;
  value: string;
};

export default function NumberCard({title, value}: Readonly<NumberCardProps>) {
  const {sx, eventHandlers} = hoverHighlight();
  return (
    <Card
      variant="outlined"
      sx={{height: '100%', flexGrow: 1, ...sx}}
      {...eventHandlers}
    >
      <CardContent>
        <Typography variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4" component="p" sx={{textAlign: 'center'}}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}
