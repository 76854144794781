import React from 'react';

const Logo: React.FC = () => {
  return (
    <div>
      <div className="Title">
        <img
          src="/images/waterwise_white.png"
          alt="WaterWise logo"
          className="center"
          style={{width: '65px'}}
        ></img>
        <h1>
          <span className="water">Water</span>
          <span className="wise">Wise</span>
        </h1>
      </div>
    </div>
  );
};

export default Logo;
