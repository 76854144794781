import React, {useState, useEffect} from 'react';
// eslint-disable-next-line n/no-unpublished-import
import Spline from '@splinetool/react-spline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {hoverHighlight} from './hoverHighlight';

export type StreamCardProps = {
  title: string;
  data: {name: string; quantity: string}[];
};

export default function Stream({title, data}: Readonly<StreamCardProps>) {
  const [sceneUrl, setSceneUrl] = useState<string>('');
  const [average, setAverage] = useState<number | null>(null);

  useEffect(() => {
    if (data.length > 0) {
      const total = data.reduce(
        (sum, item) => sum + parseFloat(item.quantity),
        0
      );
      const calculatedAverage = total / data.length;
      setAverage(calculatedAverage);
      setSceneUrl(determineScene(calculatedAverage));
    }
  }, [data]);

  const determineScene = (waterQualityAvg: number) => {
    if (waterQualityAvg < 0.5) {
      return 'https://prod.spline.design/0dIybDBJzHc2d3fM/scene.splinecode';
    } else if (waterQualityAvg >= 0.5 && waterQualityAvg < 1) {
      return 'https://prod.spline.design/3ptUzvhkAZllnh5K/scene.splinecode';
    } else {
      return 'https://prod.spline.design/3wkEFodu8nP94-jg/scene.splinecode';
    }
  };

  const {sx, eventHandlers} = hoverHighlight();

  return (
    <Card
      variant="outlined"
      sx={{height: '100%', flexGrow: 1, width: '100%', ...sx}}
      {...eventHandlers}
    >
      <CardContent>
        <Typography component="h6" gutterBottom>
          {title}
        </Typography>
        {sceneUrl && <Spline scene={sceneUrl} />}
      </CardContent>
    </Card>
  );
}
