import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';

interface LakeFilterProps {
  lakes: string[];
  selectedLake: string;
  onLakeChange: (event: SelectChangeEvent) => void;
  dataTestId: string;
}

export default function LakeSelect({
  lakes,
  selectedLake,
  onLakeChange,
  dataTestId,
}: LakeFilterProps) {
  useEffect(() => {
    if (!selectedLake && lakes.length > 0) {
      onLakeChange({target: {value: lakes[0]}} as SelectChangeEvent);
    }
  }, [selectedLake, lakes, onLakeChange]);
  return (
    <Box
      sx={{
        mb: 2,
        width: '20%',
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="lake-select-label">Select Lake</InputLabel>
        <Select
          labelId="lake-select-label"
          value={selectedLake}
          label="Select Lake"
          onChange={onLakeChange}
          inputProps={{'data-testid': dataTestId}}
        >
          {lakes.map((lakes, i) => (
            <MenuItem value={lakes} key={i}>
              {lakes}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
